import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const suiteTypeToSections = (sectionArray, suite) => {
  return sectionArray.map(section => {
    if (section._type === 'multiSection') {
      const newMultiSection = section.sections.map(sect => {
        return { ...sect, suiteType: suite };
      });
      return { ...section, sections: newMultiSection };
    }
    return { ...section, suiteType: suite };
  });
};

const SuiteBenefitsPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanitySuitebenefitspage === null) {
    return null;
  }
  const data = useLocalize(rawData, locale);
  const suiteBenefitsPageProps = {
    id: data.sanitySuitebenefitspage._id,
    metadata: { ...data.sanitySuitebenefitspage._rawRoute, href },
    sections: suiteTypeToSections(
      data.sanitySuitebenefitspage._rawSections,
      data.sanitySuitebenefitspage.suiteType
    ),
    suiteType: data.sanitySuitebenefitspage.suiteType,
    bubbleCtaSection: data.sanitySuitebenefitspage._rawBubbleCtaSection,
  };

  return (
    <Layout
      menuColor="gray"
      locale={locale}
      {...suiteBenefitsPageProps.metadata}
      sanityId={suiteBenefitsPageProps.id}
    >
      {renderPageSections(suiteBenefitsPageProps.sections)}
      {suiteBenefitsPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...suiteBenefitsPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

SuiteBenefitsPage.propTypes = {
  data: PropTypes.shape({
    sanitySuitebenefitspage: {},
  }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

SuiteBenefitsPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default SuiteBenefitsPage;

export const query = graphql`
  query($slug: String) {
    sanitySuitebenefitspage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      suiteType
    }
  }
`;
